import getApi from '../api';

function findAll(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/logs/email', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function resendEmail(id) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post(`/logs/email/resend/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  resendEmail
};
