<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <h1>Dados bancários</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <v-checkbox
              label="Utiliza conta de pagamento"
              v-model="bank.paymentAccount"
              dense
            />
          </v-col>
          <template v-if="bank.paymentAccount">
            <v-col
              class="py-0"
              cols="12"
              md="3"
            >
              <v-autocomplete
                label="Banco vinculado"
                :item-text="itemTextBank"
                clearable
                item-value="code"
                dense
                outlined
                hint="Este campo é obrigatório"
                v-disabled-icon-focus
                v-model="bank.linkedBank"
                :items="listOfBanks"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col
              class="py-0"
              cols="12"
              md="3"
            >
              <v-autocomplete
                label="Banco"
                :item-text="itemTextBank"
                clearable
                item-value="code"
                dense
                outlined
                hint="Este campo é obrigatório"
                v-disabled-icon-focus
                v-model="bank.bank"
                :items="listOfBanks"
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="bank.agency"
                outlined
                dense
                label="Agência"
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="bank.account"
                outlined
                dense
                label="Conta"
              />
            </v-col>
            <v-col
              class="py-0"
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="bank.pix"
                outlined
                dense
                label="PIX"
              />
            </v-col>
          </template>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <legend>Limite de entrada e saída?</legend>
            <v-radio-group
              row
              v-model="bank.useInOutLimit"
              class="py-0"
              dense
            >
              <v-radio
                :value="true"
                label="Sim"
              />
              <v-radio
                :value="false"
                label="Não"
              />
            </v-radio-group>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <legend>Limite em qual moeda?</legend>
            <v-radio-group
              row
              v-model="bank.useUsdLimit"
              class="py-0"
              dense
            >
              <v-radio
                :value="true"
                label="Dólar americano - USD"
              />
              <v-radio
                :value="false"
                label="Real - BRL"
              />
            </v-radio-group>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="3"
          >
            <legend>Tarifa em qual moeda?</legend>
            <v-radio-group
              row
              v-model="bank.useUsdExchangeContract"
              class="py-0"
              dense
            >
              <v-radio
                :value="true"
                label="Dólar americano - USD"
              />
              <v-radio
                :value="false"
                label="Real - BRL"
              />
            </v-radio-group>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <legend>Cashback de tarifa?</legend>
            <v-radio-group
              row
              v-model="bank.exchangeContractCashBack"
              class="py-0"
              dense
            >
              <v-radio
                :value="true"
                label="Sim"
              />
              <v-radio
                :value="false"
                label="Não"
              />
            </v-radio-group>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="2"
          >
            <MoneyField
              label="Tarifa"
              v-model="bank.exchangeContractCost"
              :prefix="bank.useUsdExchangeContract ? 'USD ' : 'R$ '"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="1"
          >
            <NumberField
              v-model="bank.commission"
              outlined
              dense
              label="Comissão(%)"
              suffix="%"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Cache
import bankCache from "../../../../common/cache/bank-cache";

// Components
import MoneyField from "@/components/comum/MoneyField";
import NumberField from "@/components/comum/NumberField";

export default {
  name: "BankFinancial",
  components: { MoneyField, NumberField },
  model: {
    prop: "bankProp",
    event: "onChange",
  },
  props: {
    bankProp: {
      type: Object,
    },
  },
  data() {
    return {
      bank: this.bankProp,
      listOfBanks: [],
    };
  },
  watch: {
    bank() {
      this.$emit("onChange", this.bank);
    },
    "bank.paymentAccount"() {
      this.bank.linkedBank = null;
      this.bank.bank = null;
    },
  },
  async mounted() {
    this.listOfBanks = await bankCache.getBankList();
  },
  computed: {
    itemTextBank() {
      return (item) => !item.code ? '000 - ' + item.name.toUpperCase() : item.code + ' - ' + item.name.toUpperCase();
    }
  },
  methods: {}
};
</script>

<style></style>
