import getApi from '../api';

function add(operation) {
  operation = operation || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/operations', operation)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function find(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/operations/', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(operation) {
  operation = operation || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/operations/${operation.id}`, operation)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/operations/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function download(documentId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/operation/documents/' + documentId + '/download', { responseType: 'blob' })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function massUpdateComissions(filters, comission) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put('/operations/comissions', { comission }, { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function getOperations(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/operations/', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function creditOperationCommissions(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put('/operations/credit', {}, { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function updateStatus(operationId, workflow) {
  workflow = workflow || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/operations/${operationId}/update-status`, workflow)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function cancelOperation(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .delete(`/operations/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  add,
  find,
  update,
  findId,
  download,
  massUpdateComissions,
  getOperations,
  creditOperationCommissions,
  updateStatus,
  cancelOperation
};
