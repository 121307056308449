import getApi from '../api';

function add(formData) {
  formData = formData || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api({
      method: 'post',
      url: '/customer/documents',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findAll(customerId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/customer/${customerId}/documents`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function remove(documentId) {
  documentId = documentId || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .delete(`/customer/documents/${documentId}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function download(documentId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/customer/documents/' + documentId + '/download', { responseType: 'blob' })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findExternalAccess() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/customer/documents/external-access`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  remove,
  add,
  download,
  findExternalAccess
};
