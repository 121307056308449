<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <v-card class="box card-cambio">
        <h1>Emails operacionais</h1>
        <v-divider class="py-3" />
        <v-row>
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <h2>Cadastro</h2>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Primeiro email"
              v-model="correspondent.firstRegisterEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Segundo email"
              v-model="correspondent.secondRegisterEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Terceiro email"
              v-model="correspondent.thirdRegisterEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <v-divider class="py-3" />
          </v-col>

          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <h2>Operação</h2>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Primeiro email"
              v-model="correspondent.firstOperationEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Segundo email"
              v-model="correspondent.secondOperationEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Terceiro email"
              v-model="correspondent.thirdOperationEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <v-divider class="py-3" />
          </v-col>

          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <h2>Documentação</h2>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Primeiro email"
              v-model="correspondent.firstDocumentationEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Segundo email"
              v-model="correspondent.secondDocumentationEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Terceiro email"
              v-model="correspondent.thirdDocumentationEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <v-divider class="py-3" />
          </v-col>

          <v-col
            class="py-0"
            cols="12"
            md="12"
          >
            <h2>Financeiro</h2>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Primeiro email"
              v-model="correspondent.firstFinancialEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Segundo email"
              v-model="correspondent.secondFinancialEmail"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="4"
          >
            <MailField
              label="Terceiro email"
              v-model="correspondent.thirdFinancialEmail"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

// Components
import MailField from "@/components/comum/MailField";

export default {
  name: "CorrespondentContactEmail",
  components: { MailField },
  model: {
    prop: "correspondentProp",
    event: "onChange",
  },
  props: {
    correspondentProp: {
      type: Object,
    },
  },
  data() {
    return {
      correspondent: this.correspondentProp,
    };
  },
  watch: {
    correspondent() {
      this.$emit("onChange", this.correspondent);
    },
  },
};
</script>

<style></style>
