import getApi from '../api';

function add(customer) {
  customer = customer || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/customers', customer)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(customer) {
  customer = customer || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/customers/${customer.id}`, customer)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findAll(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/customers', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/customers/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function inactive(customer) {
  customer = customer || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .patch(`/customers/${customer.id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function updateStatus(customerId, statusUpdateDto) {
  statusUpdateDto = statusUpdateDto || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/customers/${customerId}/update-status`, statusUpdateDto)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function addLimit(idCustomer, idExchangeBank, limit) {
  idCustomer = idCustomer || {};
  idExchangeBank = idExchangeBank || {};
  limit = limit || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post(`/customers/${idCustomer}/bank/${idExchangeBank}/limits`, limit)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findExchangeBanks(id, idType) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/customers/${id}/exchange-type/${idType}/banks`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function getLimits(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/customers/limits/`, { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function downloadRegisterForm(customerId, exchangeBankId){
  // eslint-disable-next-line no-async-promise-executor
  return  new Promise(async (resolve, reject) =>{
    let api = await getApi();

    return api.get('/customers/'+ customerId + '/bank/' + exchangeBankId + '/form/download' , { responseType: 'blob' })
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  findAll,
  findId,
  inactive,
  add,
  update,
  updateStatus,
  addLimit,
  getLimits,
  findExchangeBanks,
  downloadRegisterForm,
};
