import getApi from '../api';

function findAll() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/configurations')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findById(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/configurations/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findBySession(session) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/configurations/session/${session}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findByGroup(group) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/configurations/group/${group}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function updateList(configurations) {
  configurations = configurations || [];

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/configurations/list`, configurations)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  findById,
  findBySession,
  findByGroup,
  updateList
};
