<template>
  <div :id="id">
    <v-menu
      v-model="openCalendar"
      :close-on-content-click="false"
      transition="scale-transition"
      nudge-top="0"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template #activator="{ on }">
        <v-text-field
          :id="id ? 'textfield-data-' + id : id"
          :class="classeId ? 'textfield-data-' + classeId : classeId"
          v-model="dataFormatada"
          outlined
          :label="label"
          autocomplete="off"
          prepend-inner-icon="event"
          @change="atualizarDataModificada"
          @click="
            dataDatePicker =
              converterDataFormatadaEmDataPadraoOnClick(dataFormatada)
          "
          @blur="
            dataDatePicker =
              converterDataFormatadaEmDataPadraoOnBlur(dataFormatada)
          "
          @keyup.enter="
            dataDatePicker =
              converterDataFormatadaEmDataPadraoOnEnter(dataFormatada)
          "
          @keyup.tab="
            dataDatePicker =
              converterDataFormatadaEmDataPadraoOnTab(dataFormatada)
          "
          @keydown.tab="openCalendar = false"
          :rules="rules"
          :error="!!mensagemDeErro || dataInvalida"
          :error-messages="mensagemDeErro"
          v-mask="'##/##/####'"
          :return-masked-value="true"
          :hint="hint"
          persistent-hint
          :clearable="clearable"
          v-on="on"
          maxlength="10"
          @click:prepend-inner="abrirCalendarioPeloIconeOnClick"
          update:error
          :disabled="desabilitar"
          ref="campoData"
          dense
        />
      </template>
      <v-date-picker
        v-model="dataDatePicker"
        :min="dataMinima"
        :max="dataMaxima"
        :no-title="!apresentarTitulo"
        @input="openCalendar = false"
        locale="pt"
        update:picker-date
        @change="atualizarDataModificada"
        :color="cor"
        :allowed-dates="allowedDates"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
import dateUtils from "../../../../common/utils/date";

export default {
  model: {
    prop: "entrada",
    event: "onChange",
  },
  props: {
    id: { type: String, required: false },
    classeId: { type: String, required: false },
    entrada: { type: String, default: undefined },

    //A propriedade "dataEmArray" foi criada para comportar casos em que o sistema espera array com um elemento.
    //Deve ser utilizada com .sync no componente pai e em casos de extrema necessidade.
    dataEmArray: { type: Array, default: () => [] },
    label: { type: String, default: undefined },
    dataSelecionada: undefined,
    mensagemDeErro: { type: String, required: false },
    rules: { type: Array, required: false },
    hint: { type: String, default: "" },
    dataMinima: { type: String, default: "1900-01-01" },
    dataMaxima: { type: String, default: "2200-12-31" },
    horarioPadrao: { type: String, default: "12:00:00" },
    cor: { type: String, default: "primary" },
    apresentarTitulo: { type: Boolean, default: false },
    desabilitar: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    allowedDates: { type: Function, default: undefined },
  },

  data() {
    return {
      dataDeHoje: moment().locale("pt-br"),
      dataDatePicker: moment().locale("pt-br").format("YYYY/MM/DD"),
      dataFormatada: this.dataSelecionada,
      openCalendar: false,
      dataInvalida: false,
    };
  },

  mounted() {
    this.setNovaDataManualmente(this.entrada);
  },

  watch: {
    dataEmArray: {
      immediate: true,
      handler(novaData) {
        let data = novaData && novaData[0];
        this.dataDatePicker = this.retornarDataEmFormatoValido(data);
        this.dataFormatada = this.formatarDataParaPadraoBrasileiro(data);
      },
    },

    entrada(novaData) {
      this.setNovaDataManualmente(novaData);
    },

    dataDatePicker() {
      this.setNovaDataManualmente(this.dataDatePicker);
      this.dataDatePicker = this.retornarDataEmFormatoValido(
        this.dataDatePicker
      );
      this.dataFormatada = this.formatarDataParaPadraoBrasileiro(
        this.dataDatePicker
      );
    },

    dataSelecionada() {
      this.dataFormatada = this.dataSelecionada;
    },
  },

  methods: {
    abrirCalendarioPeloIconeOnClick() {
      this.dataDatePicker = this.converterDataFormatadaEmDataPadraoOnClick();
      this.openCalendar = true;
    },

    retornarDataEmFormatoValido(data) {
      if (data === "Invalid date") {
        return this.dataDeHoje.format("YYYY-MM-DD");
      } else if (!data) {
        return undefined;
      }

      let anoMinimoPadrao = 1900;
      let anoMaximoPadrao = 2200;

      let anoMinimo =
        (this.dataMinima && parseInt(this.dataMinima.substring(0, 4))) ||
        anoMinimoPadrao;
      let anoMaximo =
        (this.dataMaxima && parseInt(this.dataMaxima.substring(0, 4))) ||
        anoMaximoPadrao;
      let anoInformado = parseInt(data.substring(0, 4));
      let mesEDia = data.substring(4, data.length);

      data = anoInformado < anoMinimo ? anoMinimo + mesEDia : data;
      data = anoInformado > anoMaximo ? anoMaximo + mesEDia : data;

      return data;
    },

    setNovaDataManualmente(novaData) {
      this.$nextTick(() => {
        this.dataDatePicker = !novaData
          ? undefined
          : moment(novaData).locale("pt-br").format("YYYY-MM-DD");
        // if (!novaData) {
        //   this.$refs.campoData.clearableCallback();
        // }

        this.atualizarDataModificada();
      });
    },

    atualizarDataModificada() {
      let dataEntrante =
        this.entrada || (this.dataEmArray && this.dataEmArray[0]);
      let dataEntranteDate = new Date(dataEntrante);
      dataEntranteDate.setUTCHours(12);
      dataEntranteDate.setUTCMinutes(0);
      dataEntranteDate.setUTCSeconds(0);
      dataEntranteDate.setUTCMilliseconds(0);

      if (
        dataEntrante === this.dataDatePicker ||
        (dataEntrante &&
          this.dataDatePicker &&
          moment(dataEntranteDate).locale("pt-br").diff(new Date(this.dataDatePicker).setUTCHours(12), "days") === 0))
        return; // para evitar o evento de change caso a data esteja sendo modificada programaticamente ou não haja modificacao

      this.$nextTick(() => {
        this.$emit(
          "dataDatePicker",
          this.retornarDataEmFormatoValido(this.dataDatePicker)
        );
        this.$emit(
          "dataFormatada",
          this.formatarDataParaPadraoBrasileiro(this.dataDatePicker)
        );
        let data = !this.dataDatePicker
          ? undefined
          : dateUtils.formatDateAndHourIso(
              this.dataDatePicker,
              this.horarioPadrao
            );
        let dataUtc = new Date(moment.utc(data)).toISOString();

        this.$emit("update:dataEmArray", dataUtc ? [dataUtc] : []); // ATENCAO - A ORDEM DOS EVENTOS INTERFERE NO RESULTADO
        this.$emit("onChange", dataUtc);
      });
    },

    formatarDataParaPadraoBrasileiro(dataSemFormatacao) {
      if (!dataSemFormatacao) {
        return undefined;
      }

      this.dataInvalida = !this.dataValida(dataSemFormatacao);

      return moment(dataSemFormatacao, "YYYY-MM-DD")
        .locale("pt-br")
        .format("DD/MM/YYYY");
    },

    converterDataFormatadaEmDataPadrao(dataFormatada) {
      if (!dataFormatada) {
        return undefined;
      }

      return moment(dataFormatada, "DD/MM/YYYY")
        .locale("pt-br")
        .format("YYYY-MM-DD");
    },

    converterDataFormatadaEmDataPadraoOnClick(dataFormatada) {
      return this.converterDataFormatadaEmDataPadrao(dataFormatada);
    },

    converterDataFormatadaEmDataPadraoOnBlur(dataFormatada) {
      return this.converterDataFormatadaEmDataPadrao(dataFormatada);
    },

    converterDataFormatadaEmDataPadraoOnEnter(dataFormatada) {
      return this.converterDataFormatadaEmDataPadrao(dataFormatada);
    },

    converterDataFormatadaEmDataPadraoOnTab(dataFormatada) {
      return this.converterDataFormatadaEmDataPadrao(dataFormatada);
    },

    dataValida(dataASerVerificada) {
      return moment(dataASerVerificada).isValid();
    },
  },
};
</script>
