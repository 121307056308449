import TemplateType from "@/model/generic/template-type-model";

export default class Template {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.description = obj.description;
    this.content = obj.content;
    this.userId = obj.userId;
    this.typeId = obj.typeId;
    this.type = new TemplateType(obj.type);
    this.subject = obj.subject;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
    this.inativatedAt = obj.inativatedAt;
    this.format = obj.format;
    this.landscape = obj.landscape;
  }
}