import getApi from '../api';

function find(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/customers/exchange-banks', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function updateStatus(idCustomer, idExchangeBank, statusUpdateDto) {
  idCustomer = idCustomer || {};
  idExchangeBank = idExchangeBank || {};
  statusUpdateDto = statusUpdateDto || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .patch(`/customers/${idCustomer}/bank/${idExchangeBank}/update-status`, statusUpdateDto)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  find,
  updateStatus
};
