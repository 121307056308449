import getApi from '../api';

function add(correspondent) {
  correspondent = correspondent || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/correspondents', correspondent)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(correspondent) {
  correspondent = correspondent || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/correspondents/${correspondent.id}`, correspondent)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findAll() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/correspondents')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/correspondents/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function addIndicated(indicatorId, correspondent) {
  correspondent = correspondent || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post(`/correspondents/${indicatorId}/indicated`, correspondent)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findIndicated(indicatorId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/correspondents/${indicatorId}/indicated`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findIndicators() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/correspondents/indicators`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update,
  addIndicated,
  findIndicated,
  findIndicators
};
