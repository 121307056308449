'use strict';

import moment from 'moment-timezone';
import momentBusinessDays from '../plugins/moment/moment-business-days';
const TIMEZONE = 'America/Sao_Paulo';

momentBusinessDays.updateLocale('us', {
  /* Feriados específicos, em casos onde apenas um dia específico de um ano específico é feriado */
  holidays: [],
  /* Formato em que os feriados estão */
  holidayFormat: 'DD/MM/YYYY',
  /* Força um dia como dia útil */
  forcedBusinessDays: [],
  /* Formato em que os dias forçados como útil estão */
  forcedBusinessDaysFormat: 'DD/MM/YYYY',
  /* Dias da semana que serão considerado como úteis. No padrão js, Domingo = 0 até Sábado = 7 */
  workingWeekdays: [1, 2, 3, 4, 5],
  /* Callback para filtrar os feriados. Seria o ponto para usar os feriados recorrentes */
  holiday: function (someMoment) {
    /* Meses no padrão js, Janeiro = 0 até Dezembro = 11 Exemplo de filtro:
    return someMoment.month() === 6 && someMoment.date() === 4; */
    return false;
  }
});

function maskDateIso(date, valueDateEmpty) {
  if (date) {
    return moment.tz(date, TIMEZONE).format('DD/MM/YYYY');
  }

  return valueDateEmpty ? valueDateEmpty : null;
}

function maskDateAndHourIso(date, valueDateEmpty) {
  if (date) {
    return moment.tz(date, TIMEZONE).format('DD/MM/YYYY HH:mm:ss');
  }

  return valueDateEmpty ? valueDateEmpty : null;
}

function maskDateAndHourWithoutSeconds(date, valueDateEmpty) {
  if (date) {
    return moment.tz(date, TIMEZONE).format('DD/MM/YYYY HH:mm');
  }

  return valueDateEmpty ? valueDateEmpty : null;
}

function formatDateAndHourIso(date, hour) {
  return `${moment.tz(date, TIMEZONE).format('YYYY-MM-DD')}T${hour || '00:00:00'}`;
}

function endDateBiggerOrEqualStartDate(startDate, endDate) {
  return (
    !startDate ||
    startDate == '' ||
    !endDate ||
    endDate == '' ||
    new Date(startDate) <= new Date(endDate)
  );
}

function maskHourWithoutSeconds(date, valueDateEmpty) {
  if (date) {
    return moment.utc(date).locale('pt-br').format('HH:mm');
  }

  return valueDateEmpty ? valueDateEmpty : null;
}

function isBusinessDay(date) {
  return momentBusinessDays(moment.utc(date)).isBusinessDay();
}

function addBusinessDay(date, amount) {
  amount = amount || 0;
  return momentBusinessDays(moment.utc(date)).businessAdd(amount)._d;
}

export default {
  maskDateIso,
  maskDateAndHourIso,
  maskDateAndHourWithoutSeconds,
  formatDateAndHourIso,
  endDateBiggerOrEqualStartDate,
  maskHourWithoutSeconds,
  isBusinessDay,
  addBusinessDay
};
