import axios from 'axios';
import utilsStorage from '@/utils/storage';

async function loadConfig() {
  try {
    const response = await fetch('/config.json');

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Erro ao carregar a configuração:', error);

    return {}; // Retorne um objeto vazio ou algum fallback
  }
}

async function getApi() {
  const config = await loadConfig();

  const api = axios.create({
    baseURL: config.urlApi
  });

  api.interceptors.request.use(async (config) => {
    var token = await utilsStorage.getTokenOnStorage();
    config.headers.Authorization = token;

    return config;
  });

  api.interceptors.response.use(
    (response) => {
      if (response.status === 401 && response.config.url !== '/login') {
        utilsStorage.removeAuth();

        setTimeout(() => {
          window.location.href = '/login';
        }, 1000);
      }

      return response;
    },
    (error) => {
      if (!error.response) {
        return Promise.reject(
          `Não foi possível se conectar com o servidor. Se essa mensagem persistir, por favor, contate o suporte técnico.`
        );
      }

      if (error.response.status === 401 && error.response.config.url !== '/login') {
        utilsStorage.removeAuth();

        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);

        return Promise.reject(
          `Seu login de acesso expirou, efetue o login novamente para acessar o sistema.`
        );
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export default getApi;
