<template>
  <v-row>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <v-radio-group
        row
        class="py-0"
        v-model="customer.isBrazilian"
        :mandatory="true"
        :rules="[validationIsRequiredRadioBtn]"
      >
        <v-radio
          :value="true"
          :label="'Brasileiro'"
        />
        <v-radio
          :value="false"
          :label="'Estrangeiro'"
        />
        <small class="required">Informação obrigatória</small>
      </v-radio-group>
    </v-col>
    <v-col
      v-if="isBrazilian"
      class="py-0"
      cols="12"
      md="1"
    >
      <CpfOrCnpjField
        v-model="customer.cpfCnpj"
        :rules="[validationIsRequiredField]"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      :lg="isBrazilian ? 3 : 4"
    >
      <v-text-field
        outlined
        dense
        label="Nome completo"
        v-model="customer.name"
        counter="200"
        maxlength="200"
        :rules="[validationIsRequiredField]"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <Datepicker
        v-model="customer.birthDate"
        label="Data de nascimento"
        v-disabled-icon-focus
        :rules="[validationIsRequiredField]"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <v-radio-group
        :rules="[validationIsRequiredRadioBtn]"
        row
        class="py-0"
        v-model="customer.sex"
      >
        <v-radio
          value="M"
          :label="'Masculino'"
        />
        <v-radio
          value="F"
          :label="'Feminino'"
        />
      </v-radio-group>
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <v-autocomplete
        label="Tipo de documento"
        :items="isBrazilian ? listOfIdentificationDocumentTypes : [listOfIdentificationDocumentTypes[4]]"
        item-text="description"
        item-value="id"
        v-model="customer.identificationDocumentType"
        return-object
        clearable
        dense
        outlined
        v-disabled-icon-focus
        hint="Este campo é obrigatório"
        :rules="[validationIsRequiredAutocompleteId]"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      :lg="isBrazilian ? 1 : 2"
    >
      <v-text-field
        outlined
        dense
        inputmode="decimal"
        label="Nº do documento"
        v-model="customer.documentNumber"
        :rules="[validationIsRequiredField]"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <Datepicker
        v-model="customer.documentIssueDate"
        label="Data de emissão"
        v-disabled-icon-focus
        :rules="[validationIsRequiredField]"
        @onChange="changeExpirationDate"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <Datepicker
        v-model="customer.documentDueDate"
        label="Data de validade"
        v-disabled-icon-focus
        :rules="[validationIsRequiredField]"
        :hint="hint"
      />
    </v-col>
    <v-col
      v-if="isBrazilian"
      class="py-0"
      cols="12"
      md="1"
    >
      <UFField
        v-model="customer.state"
        label="UF"
        :rules="[validationIsRequiredAutocompleteText]"
      />
    </v-col>
    <v-col
      v-if="isBrazilian"
      class="py-0"
      cols="12"
      md="2"
    >
      <v-text-field
        outlined
        dense
        label="Órgão expedidor"
        v-model="customer.issuingAgency"
        :rules="[validationIsRequiredField]"
      />
    </v-col>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <v-autocomplete
        label="Estado civil"
        :items="listOfMaritalStatus"
        item-text="description"
        item-value="id"
        v-model="customer.additional.maritalStatus"
        return-object
        clearable
        dense
        outlined
        hint="Este campo é obrigatório"
        v-disabled-icon-focus
        :rules="[validationIsRequiredAutocompleteId]"
        autocomplete="null"
      />
    </v-col>
    <template v-if="isMarried && isBrazilian">
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-autocomplete
          label="Regime de Casamento"
          :items="listOfMarriageSystem"
          item-text="description"
          item-value="id"
          v-model="customer.additional.marriageSystem"
          return-object
          clearable
          dense
          outlined
          hint="Este campo é obrigatório"
          v-disabled-icon-focus
          :rules="[validationIsRequiredAutocompleteId]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="4"
      >
        <v-text-field
          outlined
          dense
          label="Nome completo do Cônjuge"
          v-model="customer.additional.spouseName"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <CpfOrCnpjField
          v-model="customer.additional.spouseCPF"
          :rules="[validationIsRequiredField]"
        />
      </v-col>
    </template>

    <v-col
      class="py-0"
      cols="12"
      md="2"
      v-if="!isBrazilian"
    >
      <CountryField
        label="País de nascimento"
        v-model="customer.birthCountry"
        :rules="[validationIsRequiredAutocompleteText]"
      />
    </v-col>

    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <MoneyField
        label="Renda média mensal"
        prefix="R$ "
        v-model="customer.additional.averageMonthlyIncome"
        :rules="[validationIsRequiredFieldNumeric]"
      />
    </v-col>

    <v-col
      v-if="!isCompleteRemittance"
      class="py-0"
      cols="12"
      md="2"
    >
      <v-text-field
        v-model="customer.occupation.profession"
        outlined
        dense
        label="Profissão"
        :rules="[validationIsRequiredField]"
      />
    </v-col>

    <template v-if="isBasicRemittance || isCompleteRemittance">
      <v-col
        class="py-0"
        cols="12"
        md="2"
      >
        <v-autocomplete
          label="Motivo do envio"
          :items="operationNatureList"
          v-model="customer.additional.operationNature"
          item-text="name"
          item-value="id"
          return-object
          clearable
          dense
          outlined
          hint="Este campo é obrigatório"
          v-disabled-icon-focus
          :rules="[validationIsRequiredAutocompleteId]"
        />
      </v-col>
      <template v-if="customer.additional.operationNature.id == 5">
        <v-col
          class="py-0"
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="customer.additional.dependentName"
            outlined
            dense
            label="Nome Completo do Dependente"
            :rules="[validationIsRequiredField]"
          />
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="customer.additional.dependentKinship"
            outlined
            dense
            label="Grau de Parentesco do Dependente"
            :rules="[validationIsRequiredField]"
          />
        </v-col>
      </template>
    </template>
    <v-col
      class="py-0"
      cols="12"
      md="2"
    >
      <v-autocomplete
        label="Corban"
        :items="correspondentsList"
        v-model="customer.correspondent"
        item-value="id"
        item-text="name"
        return-object
        dense
        clearable
        outlined
        v-disabled-icon-focus
        v-if="isAdministrativeAccess"
      />
    </v-col>
  </v-row>
</template>


<script>
// Components
import CpfOrCnpjField from "@/components/comum/CpfOrCnpjField.vue";
import Datepicker from "@/components/comum/Datepicker.vue";
import UFField from "@/components/comum/UFField.vue";
import CountryField from "@/components/comum/CountryField";
import MoneyField from "@/components/comum/MoneyField";

// Api
import apiOperationNature from "@/api/configuration/operation-nature-api";
import apiCorrespondent from "@/api/correspondent/correspondent-api";

// Models
import OperationNatureModel from "@/model/operation-nature-model";
import CorrespondentModel from "@/model/correspondent-model";

// Constants
import {
  IDENTIFICATION_DOCUMENT_TYPE,
  LIST_IDENTIFICATION_DOCUMENT_TYPE,
  MARITAL_STATUS,
  MARRIAGE_SYSTEM,
} from "@/constants/general-constants.js";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";
import mixinAuthorization from "@/mixin/mixin-authorization";
import mixinMessage from "@/mixin/mixin-message";

export default {
  name: "PFExchangeBasicCompleteRemittanceFields",
  mixins: [mixinValidationRules, mixinAuthorization, mixinMessage],
  components: { CpfOrCnpjField, Datepicker, UFField, CountryField, MoneyField },
  data() {
    return {
      hint: "",
      customer: this.customerProp,
      listOfIdentificationDocumentTypes: LIST_IDENTIFICATION_DOCUMENT_TYPE,
      listOfMaritalStatus: Object.values(MARITAL_STATUS),
      listOfMarriageSystem: Object.values(MARRIAGE_SYSTEM),
      operationNatureList: [],
      correspondentsList: [],
    };
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
    isBasicRemittance: {
      type: Boolean,
    },
    isCompleteRemittance: {
      type: Boolean,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {
    isMarried() {
      return this.customer.additional.maritalStatus &&
        this.customer.additional.maritalStatus.id == MARITAL_STATUS.MARRIED.id
        ? true
        : false;
    },
    isBrazilian() {
      return !!this.customer.isBrazilian;
    }
  },
  mounted() {
    this.getOperationNatureList();
    this.getCorrespondentsList();
  },
  methods: {

    changeExpirationDate() {
      this.hint = "";

      let conditional = !!(
        (IDENTIFICATION_DOCUMENT_TYPE.RG.id == this.customer.identificationDocumentType.id) ||
        (IDENTIFICATION_DOCUMENT_TYPE.CNH.id == this.customer.identificationDocumentType.id));

      if (conditional) {

        const expiryYear = 10;
        let expirationDate = new Date(this.customer.documentIssueDate);
        let newExpiryYear = expirationDate.getFullYear() + expiryYear;

        this.customer.documentDueDate = new Date(expirationDate.setFullYear(newExpiryYear)).toISOString();

        if (new Date(this.customer.documentDueDate) < new Date()) {
          this.hint = 'Este documento está vencido.';
        }
      }
    },
    getOperationNatureList() {
      apiOperationNature
        .findAll()
        .then((response) => {
          this.operationNatureList = response.data.map((n) => new OperationNatureModel(n));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },

    getCorrespondentsList() {
      apiCorrespondent
        .findAll()
        .then((response) => {
          this.correspondentsList = response.data.map((c) => new CorrespondentModel(c));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
  }
};
</script>


<style></style>