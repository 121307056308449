import getApi from '../api';

function add(bank) {
  bank = bank || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/exchange-banks', bank)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(bank) {
  bank = bank || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/exchange-banks/${bank.id}`, bank)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findAll(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/exchange-banks', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/exchange-banks/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update
};
