import utilsDate from "../../../utils/date";
import number from "../../../utils/number";
import utilsNumber from "../../../utils/number";
import system from "../../../utils/system";

function get(operation, externalAccessDocumentToken) {
  return [
    {
      name: "Operação",
      children: [
        {
          name: "Código",
          attribute: "Codigo_Operacao",
          title: "Código da operação",
          inline: true,
          content: () => {
            return operation?.id || '';
          }
        },
        {
          name: "Data e Hora",
          attribute: "Data_Hora_Operacao",
          title: "Data e hora da operação",
          inline: true,
          content: () => {
            return utilsDate.maskDateIso(operation?.createdAt) || '';
          }
        },
        {
          name: "Nome natureza",
          attribute: "Nome_Natureza_Da_Operacao",
          title: "Nome da natureza da operação",
          inline: true,
          content: () => {
            return operation?.nature && operation?.nature.name || '';
          }
        },
        {
          name: "Identificador natureza",
          attribute: "Id_Natureza_Da_Operacao",
          title: "Identificador da natureza da operação",
          inline: true,
          content: () => {
            return operation?.nature && operation.nature.id || '';
          }
        },
        {
          name: "Codigo natureza",
          attribute: "Codigo_Natureza_Da_Operacao",
          title: "Codigo da natureza da operação",
          inline: true,
          content: () => {
            return operation?.nature && operation.nature.code || '';
          }
        },
        {
          name: "Observação",
          attribute: "Observacao_Da_Operacao",
          title: "Observação da operação",
          inline: true,
          content: () => {
            return operation?.observation || '';
          }
        },
        {
          name: "Tipo",
          attribute: "Tipo_Da_Operacao",
          title: "Tipo de operação",
          inline: true,
          content: () => {
            return operation?.exchangeType?.description || '';
          }
        },
        {
          name: "Liquidação",
          attribute: "Liquidacao_Da_Operacao",
          title: "Liquidação de operação",
          inline: true,
          content: () => {
            return operation?.liquidation?.description || '';
          }
        },
        {
          name: "Taxa do cliente",
          attribute: "Taxa_Cliente_Da_Operacao",
          title: "Taxa do cliente na operação",
          inline: true,
          content: () => {
            return number.formatNumber(operation?.customerRate, 5) || '';
          }
        },
        {
          name: "Valor moeda extrangeira",
          attribute: "Valor_Moeda_Extrangeira_Da_Operacao",
          title: "Valor moeda extrangeira da operação",
          inline: true,
          content: () => {

            return `${operation?.currency?.code} ${number.formatNumber(operation?.amount, 2)}`;
          }
        },
        {
          name: "Valor IOF",
          attribute: "Valor_IOF_Da_Operacao",
          title: "Valor IOF de operação",
          inline: true,
          content: () => {
            return number.formatNumber(operation?.iofValue, 2) || '';
          }
        },
        {
          name: "Aliquota IOF",
          attribute: "Aliquota_IOF_Da_Operacao",
          title: "Aliquota IOF de operação",
          inline: true,
          content: () => {
            return operation?.iof ? operation.iof + "%" : '';
          }
        },
        {
          name: "Valor IR",
          attribute: "Valor_IR_Da_Operacao",
          title: "Valor IR de operação",
          inline: true,
          content: () => {
            return number.formatNumber(operation?.irValue, 2) || '';
          }
        },
        {
          name: "Aliquota IR",
          attribute: "Aliquota_IR_Da_Operacao",
          title: "Aliquota IR de operação",
          inline: true,
          content: () => {
            return operation?.ir ? operation.ir + "%" : '';
          }
        },
        {
          name: "Taxa Vet",
          attribute: "Taxa_VET_Da_Operacao",
          title: "Taxa Vet de operação",
          inline: true,
          content: () => {
            return number.formatNumber(operation?.vet, 5) || '';
          }
        },
        {
          name: "Período de entrega",
          attribute: "Periodo_De_Entrega_Da_Operacao",
          title: "Período de entrega de operação",
          inline: true,
          content: () => {
            return operation?.deliveryTime && operation.deliveryTime.description || '';
          }
        },
        {
          name: "Nome do recebedor",
          attribute: "Nome_Recebedor_Da_Operacao",
          title: "Nome do recebedor na entrega da operação",
          inline: true,
          content: () => {
            return operation?.receiverName || '';
          }
        },
        {
          name: "Telefone do recebedor",
          attribute: "Telefone_Recebedor_Da_Operacao",
          title: "Telefone do recebedor na entrega da operação",
          inline: true,
          content: () => {
            return operation?.receiverCellphone || '';
          }
        },
        {
          name: "Valor Total",
          attribute: "Valor_Total_Da_Operacao",
          title: "Valor total da operação",
          inline: true,
          content: () => {
            return number.formatCurrency('BRL', operation?.totalValue, 2) || '';
          }
        },
        {
          name: "Protocolo DI",
          attribute: "Protocolo_DI_Da_Operacao",
          title: "Protocolo DI da operação",
          inline: true,
          content: () => {
            return operation?.diDue || '';
          }
        },
        {
          name: "CNPJ do banco",
          attribute: "CNPJ_Banco_Da_Operacao",
          title: "CNPJ do banco na operação",
          inline: true,
          content: () => {
            return operation?.bank?.cnpj || '';
          }
        },
        {
          name: "Nome fantasia do banco",
          attribute: "Nome_Fantasia_Banco_Da_Operacao",
          title: "Nome fantasia na operação",
          inline: true,
          content: () => {
            return operation?.bank?.fantasyName || '';
          }
        },
        {
          name: "Nome da loja",
          attribute: "Nome_Loja_Da_Operacao",
          title: "Nome da loja na operação",
          inline: true,
          content: () => {
            return operation?.store?.name || '';
          }
        },
        {
          name: "Logradouro da loja",
          attribute: "Logradouro_Loja_Da_Operacao",
          title: "Logradouro da loja na operação",
          inline: true,
          content: () => {
            return operation?.store?.street || '';
          }
        },
        {
          name: "Numero da loja",
          attribute: "Numero_Loja_Da_Operacao",
          title: "Numero da loja na operação",
          inline: true,
          content: () => {
            return operation?.store?.number || '';
          }
        },
        {
          name: "Complemento da loja",
          attribute: "Complemento_Loja_Da_Operacao",
          title: "Complemento da loja na operação",
          inline: true,
          content: () => {
            return operation?.store?.complement || '';
          }
        },
        {
          name: "Cidade da loja",
          attribute: "Cidade_Loja_Da_Operacao",
          title: "Cidade da loja na operação",
          inline: true,
          content: () => {
            return operation?.store?.city || '';
          }
        },
        {
          name: "Bairro da loja",
          attribute: "Bairro_Loja_Da_Operacao",
          title: "Bairro da loja na operação",
          inline: true,
          content: () => {
            return operation?.store?.neighborhood || '';
          }
        },
        {
          name: "Estado da loja",
          attribute: "Estado_Loja_Da_Operacao",
          title: "Estado da loja na operação",
          inline: true,
          content: () => {
            return operation?.store?.state || '';
          }
        },
        {
          name: "CEP da loja",
          attribute: "CEP_Loja_Da_Operacao",
          title: "CEP da loja na operação",
          inline: true,
          content: () => {
            return operation?.store?.cep || '';
          }
        },
        {
          name: "Horario de funcionamento da loja",
          attribute: "Horario_De_Funcionamento_Loja_Da_Operacao",
          title: "Horario de funcionamento da loja na operação",
          inline: true,
          content: () => {
            return operation?.store?.businessHours || '';
          }
        },
        {
          name: "Nome banco nacional",
          attribute: "Nome_Banco_Nacional_Da_Operacao",
          title: "Nome banco nacional na operação",
          inline: true,
          content: () => {
            return (!operation?.account?.internationalAccount) ? operation?.account?.name || '' : operation?.paymentAccount?.name || '';
          }
        },
        {
          name: "Nome agencia nacional",
          attribute: "Nome_Agencia_Nacional_Da_Operacao",
          title: "Nome agencia nacional na operação",
          inline: true,
          content: () => {
            return (!operation?.account?.internationalAccount) ? operation?.account?.agency || '' : operation?.paymentAccount?.agency || '';
          }
        },
        {
          name: "Numero conta nacional",
          attribute: "Numero_Conta_Nacional_Da_Operacao",
          title: "Numero conta nacional na operação",
          inline: true,
          content: () => {
            return (!operation?.account?.internationalAccount) ? operation?.account?.account || '' : operation?.paymentAccount?.account || '';
          }
        },
        {
          name: "Digito conta nacional",
          attribute: "Digito_Conta_Nacional_Da_Operacao",
          title: "Digito conta nacional na operação",
          inline: true,
          content: () => {
            return (!operation?.account?.internationalAccount) ? operation?.account?.digit || '' : operation?.paymentAccount?.digit || '';
          }
        },
        {
          name: "Código SWIFT ou BIC",
          attribute: "Codigo_SWIFT_Ou_BIC_Na_Operacao",
          title: "Código SWIFT ou BIC na operação",
          inline: true,
          content: () => {
            return (operation?.account?.internationalAccount) ? operation?.account?.swiftCodeBIC || '' : '';
          }
        },
        {
          name: "Número da conta ou IBAN international",
          attribute: "Numero_Da_Conta_Ou_IBAN_Internacional_Na_Operacao",
          title: "Número da conta ou IBAN international na operação",
          inline: true,
          content: () => {
            return (operation?.account?.internationalAccount) ? operation?.account?.numberIBAN || '' : '';
          }
        },
        {
          name: "Nome banco internacional",
          attribute: "Nome_Banco_Internacional_Da_Operacao",
          title: "Nome banco internacional na operação",
          inline: true,
          content: () => {
            return (!!operation?.account?.internationalAccount) ? operation?.account?.bank || '' : '';
          }
        },
        {
          name: "Número ABA/Routing international",
          attribute: "Numero_ABA_Routing_Internacional_Na_Operacao",
          title: "Número ABA/Routing international na operação",
          inline: true,
          content: () => {
            return (!!operation?.account?.internationalAccount) ? operation?.account?.tabNumberRouting || '' : '';
          }
        },
        {
          name: "Taxa do Banco",
          attribute: "Taxa_Banco_Na_Operacao",
          title: "Taxa do banco na operação",
          inline: true,
          content: () => {
            return number.formatCurrency('BRL', operation?.bankRate, 5) || '';
          }
        },
        {
          name: "Pix",
          attribute: "Pix_Da_Operacao",
          title: "Pix da operação",
          inline: true,
          content: () => {
            return (!operation?.account?.internationalAccount) ? operation?.account?.pix || '' : operation?.paymentAccount?.pix || '';
          }
        },
        {
          name: "Beneficiário Conta Internacional",
          attribute: "Beneficiario_Conta_Internacional_Da_Operacao",
          title: "Beneficiário conta internacional da operação",
          inline: true,
          content: () => {
            return operation?.account?.ownerName || '';
          }
        },
        {
          name: "Nome banco intermediário internacional",
          attribute: "Nome_Banco_Intermediario_Internacional_Da_Operacao",
          title: "Nome banco intermediário internacional na operação",
          inline: true,
          content: () => {
            return (operation?.account?.intermediary && operation?.account?.internationalAccount) ? operation?.account?.intermediaryBankName || '' : '';
          }
        },
        {
          name: "País intermediário internacional",
          attribute: "Pais_Intermediario_Internacional_Da_Operacao",
          title: "País intermediário internacional na operação",
          inline: true,
          content: () => {
            return (operation?.account?.intermediary && operation?.account?.internationalAccount) ? operation?.account?.intermediaryCountryName || '' : '';
          }
        },
        {
          name: "Código SWIFT/ABA intermediário internacional",
          attribute: "Codigo_SWIFT_ABA_Intermediario_Internacional_Da_Operacao",
          title: "Código SWIFT/ABA intermediário internacional na operação",
          inline: true,
          content: () => {
            return (operation?.account?.intermediary && operation?.account?.internationalAccount) ? operation?.account?.intermediaryAbaSwiftCode || '' : '';
          }
        },
        {
          name: "Favorecido conta nacional",
          attribute: "Favorecido_Conta_Nacional_Da_Operacao",
          title: "Favorecido conta nacional na operação",
          inline: true,
          content: () => {
            return (!!operation?.paymentAccount?.paymentAccount) ? operation?.customer?.name || '' : operation?.bank?.companyName || '';
          }
        },
        {
          name: "Tarifa",
          attribute: "Tarifa_Da_Operacao",
          title: "Tarifa da operação",
          inline: true,
          content: () => {
            return number.formatCurrency('BRL', operation?.exchangeContractCostBrl) || '';
          }
        },
        {
          name: "URL acesso externo documentos da operação",
          attribute: "URL_Acesso_Externo_Documentos_Operacao",
          title: "URL para acesso externo de documentos - operação",
          inline: true,
          content: () => {
            let urlBase = system.getURLFrontEnd();

            return `
            <center>
              <a href="${urlBase}/operation/documents/external-acess/${externalAccessDocumentToken}" target="_blank" style="
                text-decoration: none;
                padding: 8px;
                border: none;
                width: 100px;
                display: block;
                text-align: center;
                cursor: pointer;
                background-color:#3333cc;
                border-radius: 3px;
                color: #fff;
                font-weight: 600;"
              >
                Clique aqui para acesso aos documentos
              </a>
            </center>`;
          }
        },

      ]
    }
  ]
}
export default { get }