<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <div class="box">
        <h1 class="titulo">Pré venda</h1>
        <v-divider class="py-3" />

        <v-col cols="12">
          <v-card>
            <v-col cols="12">
              <v-tabs v-model="tab">
                <v-tab key="operacoes">
                  <span>Operações internas</span>
                </v-tab>
                <v-tab key="movimentacoes">
                  <span>Operações externas</span>
                </v-tab>
              </v-tabs>
            </v-col>

            <v-tabs-items v-model="tab">
              <v-tab-item
                eager
                key="operacoes"
              >
                <v-col
                  cols="12"
                  md="12"
                >
                  <OpenOperationsReport />
                </v-col>
              </v-tab-item>

              <v-tab-item
                eager
                key="movimentacoes"
              >
                <v-col
                  cols="12"
                  md="12"
                  v-if="false"
                >
                  <OpenOperationsReport />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                 <EmptyTable
                    title="Nenhum registro encontrado"
                    icon="far fa-hand-holding-usd"
                    subtitle="Não há operações externas"
                  />
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import OpenOperationsReport from './OpenOperationsReport.vue';
import EmptyTable from "@/components/comum/EmptyTable.vue";

export default {
  name: 'PreSaleReport',

  components: {
    OpenOperationsReport,EmptyTable
  },

  data() {
    return {
      tab: null
    };
  }
};
</script>

<style scoped></style>
