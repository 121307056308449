import getApi from '../api';

function add(nature) {
  nature = nature || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/operation-natures', nature)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(nature) {
  nature = nature || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/operation-natures/${nature.id}`, nature)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findAll() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/operation-natures')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/operation-natures/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update
};
