import getApi from '../api';

function getCustomerCount(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/dashboard/customers/count', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function getOperations(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/dashboard/operations', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function getCustomerExchangeBanksDistinctCustomer(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/dashboard/customers/exchange-banks/distinct-customer', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function getEmailCount(filters) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/dashboard/email/count', { params: filters })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  getCustomerCount,
  getOperations,
  getCustomerExchangeBanksDistinctCustomer,
  getEmailCount
};
