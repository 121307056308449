import getApi from '../api';

function add(spread, correspondentId) {
  spread = spread || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post(`/correspondents/${correspondentId}/spreads`, spread)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(spread, correspondentId) {
  spread = spread || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/correspondents/${correspondentId}/spreads/${spread.id}`, spread)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findByCorrespondent(correspondentId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/correspondents/${correspondentId}/spreads`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findByCorrespondent,
  add,
  update
};
