import getApi from '../api';

function add(store, bankId) {
  store = store || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post(`/exchange-banks/${bankId}/exchange-stores`, store)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(store, bankId) {
  store = store || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/exchange-banks/${bankId}/exchange-stores/${store.id}`, store)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findByBank(bankId) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/exchange-banks/${bankId}/exchange-stores`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(storeId, bankId) {
  storeId = storeId || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/exchange-banks/${bankId}/exchange-stores/${storeId}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findCities() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/exchange-banks/exchange-stores/cities`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findByBank,
  findId,
  add,
  update,
  findCities
};
