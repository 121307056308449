import getApi from '../api';

function add(templateType) {
  templateType = templateType || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .post('/template-types', templateType)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function update(templateType) {
  templateType = templateType || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .put(`/template-types/${templateType.id}`, templateType)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findAll() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get('/template-types')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function findId(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .get(`/template-types/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

function inactivate(id) {
  id = id || {};

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let api = await getApi();

    return api
      .delete(`/template-types/${id}`)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default {
  findAll,
  findId,
  add,
  update,
  inactivate
};
