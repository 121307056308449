<template>
  <div class="ma-5">
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col
        cols="12"
        md="12"
      >
        <v-skeleton-loader
          type="heading"
          width="400px"
          class="py-4"
        />
        <v-divider />
        <v-skeleton-loader
          type="button"
          min-width="100px"
          class="py-5"
        />
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        />
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        cols="12"
        md="12"
      >
        <h1 class="titulo py-3">Auditoria</h1>
        <v-divider />
      </v-col>

      <v-col cols="12">
        <v-btn
          class="btn-secondary float-right"
          text
          @click="drawer = true"
        >
          <v-icon>mdi-filter-variant</v-icon>
          Filtrar
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-card class="card-cambio">
          <v-data-table
            :headers="columns"
            :items="audits"
            :search="search"
            sort-by="id"
            sort-desc
            :items-per-page="paginationDefault"
            item-key="id"
          >
            <template #[`item.entityDescription`]="{ item }">
              {{ item.entityDescription }}
            </template>
            <template #[`item.user`]="{ item }">
              {{ item.user.email ? item.user.email : 'Acesso externo' }}
            </template>
            <template #[`item.mutation`]="{ item }">
              {{ item.mutation }}
            </template>
            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt | dateAndHour }}
            </template>
            <template #[`item.entityId`]="{ item }">
              {{ item.entityDescription }} nº {{ item.entityId }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    icon
                    text
                    @click="previewAudit(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="far fa-eye" />
                  </v-btn>
                </template>
                <span>Visualizar registro</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      max-width="1000px"
      v-model="modalPreviewAudit"
    >
      <v-card>
        <v-card-title>
          <h3>Visualizar registro</h3>
        </v-card-title>
        <v-divider class="mt-3 pb-3" />
        <v-card-text class="pa-5">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <h2>{{ titleBeforeAudit }}</h2>
                  <pre>{{ contentBeforeAudit }}</pre>
                  <small>Última atualização em {{ this.audit.createdAt | dateAndHour }}</small>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <h2>{{ titleAfterAudit }}</h2>
                  <pre>{{ contentAfterAudit }}</pre>
                  <small>Última atualização em {{ this.audit.createdAt | dateAndHour }}</small>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            class="btn-tertiary ma-2"
            @click="closeModalPreview"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-model="drawer"
      bottom
      fixed
      temporary
      right
      :width="500"
    >
      <v-list nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">Filtro</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list-item>
        <v-list-item-content>
          <v-form id="filters-form">
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <Datepicker
                  v-model="filters.startDate"
                  label="Data de início"
                  v-disabled-icon-focus
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <Datepicker
                  v-model="filters.endDate"
                  label="Data de fim"
                  v-disabled-icon-focus
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  label="Usuário"
                  v-model="filters.userId"
                  :items="users"
                  item-text="name"
                  item-value="id"
                  clearable
                  dense
                  outlined
                  v-disabled-icon-focus
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-autocomplete
                  label="Entidade"
                  v-model="filters.entityDescription"
                  :items="listOfEntities"
                  item-text="description"
                  item-value="description"
                  clearable
                  dense
                  outlined
                  v-disabled-icon-focus
                />
              </v-col>
            </v-row>
          </v-form>
        </v-list-item-content>
      </v-list-item>

      <template #append>
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col
                cols="6"
                class="d-flex justify-center"
              >
                <v-btn
                  class="btn-tertiary"
                  width="100%"
                  text
                  @click="resetFilters"
                >
                  Limpar
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                class="d-flex justify-center"
              >
                <v-btn
                  class="btn-primary"
                  text
                  width="100%"
                  @click="filter"
                >
                  Filtrar
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
// Apis
import apiAudit from "@/api/generic/audit-api";
import apiUser from "@/api/user/user-api";

// Models
import AuditModel from "@/model/generic/audit-model";
import UserModel from "@/model/user-model";
import AuditFilterModel from "@/model/generic/audit-filter-model";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinAuthorization from "@/mixin/mixin-authorization";

// Utils
import dateUtils from "../../../../common/utils/date";

// Constants
import { ENTITY, MUTATIONS } from "../../../../common/constants/generic/types";

// Tracking
import mixpanel from "mixpanel-browser";

// Components
import Datepicker from "@/components/comum/Datepicker.vue";
import { PAGINATION_DEFAULT } from '../../constants/general-constants';

export default {
  name: "AuditControl",
  mixins: [mixinMessage, mixinAuthorization],
  components: {
    Datepicker,
  },
  filters: {
    date(value) {
      return dateUtils.maskDateIso(value);
    },
    dateAndHour(value) {
      return dateUtils.maskDateAndHourWithoutSeconds(value);
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      columns: [
        { text: "Data de cadastro", value: "createdAt", align: "center", sortable: true, },
        { text: "Entidade", value: "entityDescription", align: "left", sortable: true, },
        { text: "Entidade vincluada", value: "entityId", align: "left", sortable: true },
        { text: "Ação", value: "mutation", align: "left", sortable: true },
        { text: "Usuário vinculado", value: "user", align: "left", sortable: true },
        { text: "Observação", value: "observation", align: "left", sortable: true },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      audits: [],
      modalPreviewAudit: false,
      listOfEntities: Object.values(ENTITY),
      listOfMutations: MUTATIONS,
      users: [],
      audit: new AuditModel(),
      drawer: false,
      filters: new AuditFilterModel({
        startDate: new Date(),
        endDate: new Date(),
      }),
      paginationDefault: PAGINATION_DEFAULT,
    };
  },
  mounted() {
    this.getUsers();
    this.drawer = true;
    mixpanel.track("page_view", {
      name_of_page_viewed: "audit_control",
    });
  },

  computed: {
    titleBeforeAudit() {
      return this.audit.mutation == this.listOfMutations.CREATE.description ? `Antes da criação` : `Antes da alteração`;
    },
    titleAfterAudit() {
      return this.audit.mutation == this.listOfMutations.CREATE.description ? `Depois da criação` : `Dados alterados`;
    },
    contentBeforeAudit() {
      return this.audit.mutation == this.listOfMutations.CREATE.description ? 'Sem dados informados' : this.audit.newValue;
    },
    contentAfterAudit() {
      return this.audit.mutation == this.listOfMutations.CREATE.description ? this.audit.newValue : this.audit.diff;
    },

    minimumDate() {
      return new Date().toISOString();
    },
    showCustomer() {
      return this.audit.entityId == ENTITY.CUSTOMER.id;
    }
  },

  methods: {
    previewAudit(audit) {
      this.modalPreviewAudit = true;
      this.audit = audit;
    },
    closeModalPreview() {
      this.modalPreviewAudit = false;
    },
    getUsers() {
      apiUser
        .findAll()
        .then((response) => {
          this.users = response.data.map((u) => new UserModel(u));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        });
    },
    getAudit() {
      apiAudit
        .findAll(this.filters)
        .then((response) => {
          this.audits = response.data.map((a) => new AuditModel(a));
        })
        .catch((error) => {
          this.sendMessage(
            (error.response && error.response.data.mensagem) || error,
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    resetFilters() {
      this.filters = new AuditFilterModel({
        startDate: new Date(),
        endDate: new Date(),
      });
      this.filter();
    },

    filter() {
      this.drawer = false;
      this.loading = true;

      this.getAudit();
    },
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  height: 300px;
  overflow-x: auto;
}
</style>
